import React from "react";
import {
	Text,
	Link,
	CloudinaryImage,
	Grid,
	Col,
	VideoPlayer,
} from "@website-builder/ui/shared/elements";
import {
	AlternateSideContentContainer,
	ListItemWrapper,
	ListItemContent,
	LeftSection,
	RightSection,
} from "./style";
import { handleCTAClick } from "@website-builder/utilities/utils/DesignUtils.js";

const AlternateSideContentModule = (props) => {
	return (
		<AlternateSideContentContainer
			className="alternate-side-content-module outer-margin-wrap"
			hasDarkBackground={props.hasDarkBackground}
		>
			<Grid>
				<Col
					start={2}
					span={10}
					mdStart={1}
					mdSpan={6}
					hAlign="center"
					className="header-container"
				>
					{props.headerLabel ? (
						<Text
							dark={props.hasDarkBackground}
							className="topic-text"
							variant="label_M"
						>
							{props.headerLabel}
						</Text>
					) : null}
					{props.header ? (
						<Text
							dark={props.hasDarkBackground}
							className="header-text"
							variant="headline_2"
						>
							{props.header}
						</Text>
					) : null}
				</Col>
				{props.subHeader ? (
					<Col
						start={3}
						span={8}
						mdStart={1}
						mdSpan={6}
						hAlign="center"
						className="header-container"
					>
						<Text
							dark={props.hasDarkBackground}
							className="subheader-text"
							variant="paragraph_L"
						>
							{props.subHeader}
						</Text>
					</Col>
				) : null}
			</Grid>

			<ListItemWrapper>
				{props.itemsList?.map((item, index) => (
					<ListItemContent isMediaRightAligned={index % 2 === 0} key={index}>
						<Grid className="list-item">
							<Col start={2} span={4} mdStart={1} mdSpan={6} vAlign="center">
								<LeftSection>
									{item?.itemHeader ? (
										<Text
											dark={props.hasDarkBackground}
											className="margin-full"
											variant="headline_4"
											tag="h3"
										>
											{item?.itemHeader}
										</Text>
									) : null}
									{item?.itemSubHeader ? (
										<Text
											dark={props.hasDarkBackground}
											className="margin-half"
											variant="paragraph_L_bold"
										>
											{item?.itemSubHeader}
										</Text>
									) : null}
									{item?.itemDescription ? (
										<Text
											dark={props.hasDarkBackground}
											className="margin-full rich-text-wrapper"
											variant="paragraph_M"
											tag="div"
											dangerouslySetInnerHTML={{
												__html: item?.itemDescription,
											}}
										/>
									) : null}
									{item?.cta ? (
										<Link
											href={item?.cta?.link?.link}
											target={item?.cta?.link?.target}
											className="content-link"
											onClick={(e) =>
												handleCTAClick(
													item?.cta,
													{
														page_section: props.pageSection,
														redirect_to: item?.cta?.link?.link,
														click_text: item?.cta?.text,
													},
													{},
													e,
												)
											}
											arrowCta
											darkBg={props.hasDarkBackground}
										>
											<Text
												className="link-text"
												variant="paragraph_M_bold"
												style={{
													color: props.hasDarkBackground
														? "var(--secondary-purple-100)"
														: "var(--secondary-purple-300)",
												}}
											>
												{item?.cta?.text}
											</Text>
										</Link>
									) : null}
								</LeftSection>
							</Col>
							<Col
								start={7}
								span={5}
								mdStart={1}
								mdSpan={6}
								className="media-container"
							>
								<RightSection>
									{item?.image?.url ? (
										<CloudinaryImage
											url={item?.image?.url}
											alt={item?.image?.alt}
											lazyload
											quality="auto"
											fetchFormat="auto"
										/>
									) : null}
									{item?.video?.url && item?.video?.image ? (
										<VideoPlayer
											videoImageUrl={item?.video?.image}
											mediaUrl={item?.video?.url}
											isDarkBg={props.hasDarkBackground}
										/>
									) : null}
								</RightSection>
							</Col>
						</Grid>
					</ListItemContent>
				))}
			</ListItemWrapper>
		</AlternateSideContentContainer>
	);
};

export default AlternateSideContentModule;
