import styled from "styled-components";
import { getAnchorStyles } from "@website-builder/utilities/utils/DesignUtils.js";
import { TABLET_M_BP } from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
export const AlternateSideContentContainer = styled.div`
	padding: 80px 0;
	color: var(
		${(props) =>
			props.hasDarkBackground
				? "--primary-neutral-white"
				: "--primary-neutral-nightshade-800"}
	);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.header-container {
		text-align: center;
	}

	.topic-text {
		margin-bottom: 1rem;
		color: var(
			${(props) =>
				props.hasDarkBackground
					? "--primary-green-400"
					: "--secondary-gray-dark"}
		);
	}
	.subheader-text {
		margin-top: 1rem;
	}

	.margin-full {
		margin-bottom: 1rem;
	}
	.margin-half {
		margin-bottom: 0.5rem;
	}

	.content-link {
		direction: ltr;
	}

	.rich-text-wrapper {
		${({ hasDarkBackground }) => getAnchorStyles(hasDarkBackground)}
	}
`;

export const ListItemWrapper = styled.div`
	.list-item {
		.media-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			& img {
				border-radius: 8px;
			}
			@media only screen and (max-width: ${TABLET_M_BP}px) {
				grid-row: 1;
				margin-top: 0.5rem;
			}
		}
	}

	@media only screen and (max-width: ${TABLET_M_BP}px) {
		padding: 0;
	}
`;

export const ListItemContent = styled.div`
	&:first-child {
		margin-top: 48px;
	}
	&:not(:first-child) {
		margin-top: 80px;
	}

	direction: ${(props) => (props.isMediaRightAligned ? "ltr" : "rtl")};
	div * {
		direction: ltr;
	}
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		&:first-child {
			margin-top: 32px;
		}
		&:not(:first-child) {
			margin-top: 40px;
		}
	}
`;

export const LeftSection = styled.div`
	text-align: left;
`;

export const RightSection = styled.div`
	text-align: left;

	picture img {
		display: flex;
		width: 100%;
	}
`;
